<template>
  <div class="col-11 team-stats-popup">
    <img @click="this.setSoccerInsightsPopup(false)" class="col-2 close-popup-img" src="../assets/game/exit-icon.png">
    <div class="d-flex flex-row align-items-center text-center col-12 mt-4 mb-4">
      <div class="col-4 statistics-header">
        Statistics
      </div>
      <div class="col-4">
        <img class="badge-icon" :src="this.findImageModule(teamInsights[0].Name)">
      </div>
      <div class="col-4">
        <img class="badge-icon" :src="this.findImageModule(teamInsights[1].Name)">
      </div>
    </div>
    <div class="d-flex flex-row col-12">
      <div class="col-4 statistic-given-text">
        Last 5 Matches
      </div>
      <div class="d-flex flex-row justify-content-center col-4 matches-streak-container">
        <div class="d-flex flex-row justify-content-center">
          <div :class="`${this.displayMatchResultAbbreviationCircle(teamInsights[0].LastResultOne)[1]} text-center col-2`">
            {{ this.displayMatchResultAbbreviationCircle(teamInsights[0].LastResultOne)[0] }}
          </div>
          <div :class="`${this.displayMatchResultAbbreviationCircle(teamInsights[0].LastResultTwo)[1]} text-center col-2`">
            {{ this.displayMatchResultAbbreviationCircle(teamInsights[0].LastResultTwo)[0] }}
          </div>
          <div :class="`${this.displayMatchResultAbbreviationCircle(teamInsights[0].LastResultThree)[1]} text-center col-2`">
            {{ this.displayMatchResultAbbreviationCircle(teamInsights[0].LastResultThree)[0] }}
          </div>
          <div :class="`${this.displayMatchResultAbbreviationCircle(teamInsights[0].LastResultFour)[1]} text-center col-2`">
            {{ this.displayMatchResultAbbreviationCircle(teamInsights[0].LastResultFour)[0] }}
          </div>
          <div :class="`${this.displayMatchResultAbbreviationCircle(teamInsights[0].LastResultFive)[1]} text-center col-2`">
            {{ this.displayMatchResultAbbreviationCircle(teamInsights[0].LastResultFive)[0] }}
          </div>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-center">
        <div :class="`${this.displayMatchResultAbbreviationCircle(teamInsights[1].LastResultOne)[1]} text-center col-2`">
          {{ this.displayMatchResultAbbreviationCircle(teamInsights[1].LastResultOne)[0] }}
        </div>
        <div :class="`${this.displayMatchResultAbbreviationCircle(teamInsights[1].LastResultTwo)[1]} text-center col-2`">
          {{ this.displayMatchResultAbbreviationCircle(teamInsights[1].LastResultTwo)[0] }}
        </div>
        <div :class="`${this.displayMatchResultAbbreviationCircle(teamInsights[1].LastResultThree)[1]} text-center col-2`">
          {{ this.displayMatchResultAbbreviationCircle(teamInsights[1].LastResultThree)[0] }}
        </div>
        <div :class="`${this.displayMatchResultAbbreviationCircle(teamInsights[1].LastResultFour)[1]} text-center col-2`">
          {{ this.displayMatchResultAbbreviationCircle(teamInsights[1].LastResultFour)[0] }}
        </div>
        <div :class="`${this.displayMatchResultAbbreviationCircle(teamInsights[1].LastResultFive)[1]} text-center col-2`">
          {{ this.displayMatchResultAbbreviationCircle(teamInsights[1].LastResultFive)[0] }}
        </div>
      </div>
    </div>
    <div class="d-flex flex-row align-items-center col-12 mt-2 mb-2">
      <div class="col-4 statistic-given-text">
        Current Position
      </div>
      <div class="col-4 position-text text-center">
        {{ teamInsights[0].TeamRank }}
      </div>
      <div class="col-4 position-text text-center">
        {{ teamInsights[1].TeamRank }}
      </div>
    </div>
    <div class="d-flex flex-row align-items-center col-12 mt-2 mb-4">
      <div class="col-4 statistic-given-text">
        {{ 'Results ' + [this.selectedSportLeague.toUpperCase()] }}
      </div>
      <div class="d-flex flex-row justify-content-center col-4">
        <div class="green-circle text-center">
          {{ teamInsights[0].Wins }}
        </div>
        <div class="grey-circle text-center">
          {{ teamInsights[0].Ties }}
        </div>
        <div class="red-circle primary-bg-colour text-center">
          {{ teamInsights[0].Losses }}
        </div>
      </div>
      <div class="d-flex flex-row justify-content-center col-4">
        <div class="green-circle text-center">
          {{ teamInsights[1].Wins }}
        </div>
        <div class="grey-circle text-center">
          {{ teamInsights[1].Ties }}
        </div>
        <div class="red-circle primary-bg-colour text-center">
          {{ teamInsights[1].Losses }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "SoccerTeamInsights",
  inheritAttrs: false,
  computed: {
    ...mapState(['selectedSportLeague']),
  },
  props: ['teamInsights'],
  methods: {
    ...mapMutations(['setSoccerInsightsPopup']),
    findImageModule(teamName) {
      let teamNameReformat = teamName.split(" ").join("-").toLowerCase()
      try {
        return [require(`@/assets/teamBadges/soccer/${teamNameReformat}-logo-icon.png`)]
      } catch (e) {
        return [require(`../assets/game/default-badge.png`), this.displayTeamName(teamName)]
      }
    },
    displayTeamName(teamName) {
      this.displayClubName = true
      return teamName
    },
    displayMatchResultAbbreviationCircle(matchResult) {
      if (matchResult === 'Tie') {
        return ['D', 'grey-circle']
      } else if (matchResult === 'n/a') {
        return ['?', 'grey-circle']
      } else if (matchResult === 'Win') {
        return ['W', 'green-circle']
      } else return ['L', 'red-circle']
    },
  }
}
</script>

<style scoped>
.team-stats-popup {
  background-color: #e8e8e8;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  border-radius: 4px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close-popup-img {
  position: fixed;
  right: -25px;
  top: -26px;
}

.statistics-header {
  font-size: 22px;
  font-weight: 600;
}

.badge-icon {
  width: 40px;
}

.statistic-given-text {
  padding-left: 6px;
  font-size: 13px;
}

.matches-streak-container {
  margin-left: 3px;
  margin-right: 3px;
}

.green-circle, .grey-circle, .red-circle {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  display: inline-block;
  color: #FFFFFF;
  font-size: 10.5px;
  margin: 1.5px;
}

.green-circle {
  background-color: #00D30A;
}

.grey-circle {
  background-color: #777777;
}

.red-circle {
  background-color: #F42525;
}
</style>
