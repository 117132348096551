<template>
  <div class="d-flex flex-column align-items-center body-container mb-4" v-if="!this.isLoading">
    <div class="d-flex flex-column text-center col-12 mt-3">
      <img src="../assets/game/main-banner.png">
    </div>
    <div class="mt-2 col-12">
      <div class="d-flex flex-row justify-content-around col-12 text-center border">
        <div @click="openCloseEarnPointsPopUp()"
             class="d-flex flex-row align-items-center justify-content-center earn-points-button">
          How to Win
        </div>
        <div @click="autoPredictButtonClicked(leagueFixturesArray)" class="d-flex flex-row align-items-center justify-content-center auto-predict-button">
          Auto Predict
        </div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-around round-date-time col-12 mt-3 p-1">
      <div>{{ this.formattedDate }}</div>
      <div>{{ this.formattedTime }}</div>
    </div>
    <div class="d-flex flex-column pt-1 col-11"
         v-for="(fixture, index) in leagueFixturesArray" :key="index">
      <div class="match-badges-incrementer-container" v-if="checkMatchNotStarted(fixture)">
        <div class="d-flex flex-row pt-2 pb-2">
          <div @click="teamInsightButtonClicked(fixture.Team1Id, fixture.Team2Id)" class="more-stats-icon secondary-text">
            <div class="text-more-stats-icon">i</div>
          </div>
          <div class="d-flex flex-column align-items-center col-5">
            <img class="badge-icon" :src="this.findImageModule(fixture.Team1Name)[0]">
            <div v-if="displayClubName" class="team-name">{{ this.findImageModule(fixture.Team1Name)[1] }}</div>
          </div>
          <div class="d-flex flex-row align-items-center col-7">
            <div @click="minusButtonClicked(index, 1)"
                 class="col-3 text-center primary-text secondary-bg-colour minus-decrement">-
            </div>
            <div class="col-3 text-center score-value">{{ fixture.Team1Score }}</div>
            <div @click="plusButtonClicked(index, 1)"
                 class="col-3 text-center primary-text secondary-bg-colour plus-incrementer">+
            </div>
          </div>
        </div>
        <div class="d-flex flex-row pt-2 pb-3">
          <div class="d-flex flex-column align-items-center col-5">
            <img class="badge-icon" :src="this.findImageModule(fixture.Team2Name)[0]">
            <div v-if="displayClubName" class="team-name">{{ this.findImageModule(fixture.Team2Name)[1] }}</div>
          </div>
          <div class="d-flex flex-row align-items-center col-7">
            <div @click="minusButtonClicked(index, 2)"
                 class="col-3 text-center primary-text secondary-bg-colour minus-decrement">-
            </div>
            <div class="col-3 text-center score-value">{{ fixture.Team2Score }}</div>
            <div @click="plusButtonClicked(index, 2)"
                 class="col-3 text-center primary-text secondary-bg-colour plus-incrementer">+
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center pt-3 col-12 mb-5 submit-predictions-container">
      <button id="submitButton" @click="submitPredictionsButtonClicked" :disabled="submitButtonDisabled"
              class="submit-predictions-button">
        Submit Predictions
      </button>
      <div v-if="successfulSubmissionPopup"
           class="d-flex flex-column text-center align-items-center prediction-submitted-container">
        <div class="d-flex flex-column align-items-center background-opacity-container">
          <div class="col-10 submission-header-text mt-3">
            Prediction Submitted Successfully:
          </div>
          <div class="col-11 submission-secondary-text mt-2">
            Thanks for predicting the score for this round. All the points you earn will be entered into the daily
            prize draw!
          </div>
          <div class="col-11 submission-secondary-text mt-2">
            Predict the worlds greatest leagues with Vodacom Fantasy Predictor for a chance to WIN more airtime daily!
          </div>
          <button v-if="subscriptionStatus === 'Subscribed'" class="mt-3 col-11 join-now-button"
                  @click="predictWinClicked">PLAY PREDICT & WIN
          </button>
          <button v-if="subscriptionStatus !== 'Subscribed'" class="mt-3 col-11 join-now-button" @click="onJoinNowClicked">
            PLAY VIP for R3/day
          </button>
          <button class="mt-3 mb-2 col-11 home-button" @click="$router.back()">
            Return Home
          </button>
          <div @click="termsConditionsClicked()" v-if="subscriptionStatus !== 'Subscribed'" class="text-decoration-underline term-conditions-text mb-3">T&C Apply | Subscription Service.</div>
        </div>
      </div>
      <div v-if="doiTwoPopUp"
           class="d-flex flex-column text-center align-items-center prediction-submitted-container">
        <div class="d-flex flex-column align-items-center background-opacity-container">
          <div class="col-10 submission-header-text mt-3">
            Confirm Vodacom Fantasy Subscription
          </div>
          <div class="col-11 submission-secondary-text mt-2">
            <div>I confirm my subscription of R3.00/day (or a lesser amount).</div>
            <div>This can be later managed with your profile settings</div>
          </div>
          <button class="mt-3 col-11 join-now-button" @click="confirmSubscriptionClicked()">
            Confirm
          </button>
          <button class="mt-3 mb-2 col-11 home-button" @click="cancelSubscriptionClicked()">
            Cancel
          </button>
          <div class="sub-error-text" v-if="subscriptionError">We have encountered an error when attempting to subscribe you</div>
          <div @click="termsConditionsClicked()" v-if="subscriptionStatus !== 'Subscribed'" class="term-conditions-text mb-3">By clicking 'Confirm' I agree to the <span
              class="text-decoration-underline">Terms and conditions</span>
            of Vodacom Fantasy
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center how-to-win-container">
      <HowToWin/>
    </div>
    <div v-if="soccerInsightsPopup">
      <SoccerTeamInsights :teamInsights="teamLeagueStatistics"/>
    </div>
  </div>
</template>
<script>

import GameDataService from "@/services/gameDataService";
import UserDataService from "@/services/userDataService";
import {mapMutations, mapState} from "vuex";
import moment from "moment";
import {environmentConfig} from "@/config/enviromentConfig";
import HowToWin from "@/components/HowToWin";
import SoccerTeamInsights from "@/components/SoccerTeamInsights";
import UtilsService from "@/services/utilsService";

export default {
  name: "MakePredictionsSoccer",
  components: {SoccerTeamInsights, HowToWin},
  data() {
    return {
      successfulSubmissionPopup: false,
      leagueFixturesArray: undefined,
      submitButtonDisabled: false,
      displayClubName: false,
      teamLeagueStatistics: undefined,
      doiTwoPopUp: false,
      subscriptionError: false,
    }
  },
  computed: {
    ...mapState(['isLoading', 'roundEntered', 'user', 'subscriptionStatus', 'selectedSportLeague', 'soccerInsightsPopup'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setRoundEntered', 'setEarnPointsPopup', 'setSoccerInsightsPopup']),
    termsConditionsClicked() {
      window.open(`${environmentConfig.termsConditionsHost}`, '_self');
    },
    async teamInsightButtonClicked(teamOneId, teamTwoId) {
      await this.setTeamLeagueStatistics(teamOneId, teamTwoId);
      this.setSoccerInsightsPopup(true)
    },
    async setTeamLeagueStatistics(teamOneId, teamTwoId) {
      let homeTeamSoccerStatsResponse = await GameDataService.getTeamInsights(this.selectedSportLeague, teamOneId);
      let awayTeamSoccerStatsResponse = await GameDataService.getTeamInsights(this.selectedSportLeague, teamTwoId);
      this.teamLeagueStatistics = [homeTeamSoccerStatsResponse.data, awayTeamSoccerStatsResponse.data]
    },
    findImageModule(teamName) {
      let teamNameReformat = teamName.split(" ").join("-").toLowerCase()
      try {
        return [require(`../assets/teamBadges/soccer/${teamNameReformat}-logo-icon.png`)]
      } catch (e) {
        return [require(`../assets/teamBadges/default-badge.png`), this.displayTeamName(teamName)]
      }
    },
    async onJoinNowClicked() {
      await UtilsService.doiOne(this.user.msisdn);
      this.successfulSubmissionPopup = !this.successfulSubmissionPopup;
      this.doiTwoPopUp = !this.doiTwoPopUp;
    },
    predictWinClicked() {
      window.open(`${environmentConfig.predictorHost}/?fantasyToken=${this.user.token}`, '_self');
    },
    minusButtonClicked(index, teamNumber) {
      if (teamNumber === 1) {
        if (this.leagueFixturesArray[index].Team1Score > 0) --this.leagueFixturesArray[index].Team1Score
      } else if (this.leagueFixturesArray[index].Team2Score > 0) {
        --this.leagueFixturesArray[index].Team2Score
      }
    },
    plusButtonClicked(index, teamNumber) {
      teamNumber === 1 ? ++this.leagueFixturesArray[index].Team1Score : ++this.leagueFixturesArray[index].Team2Score;
    },
    async submitPredictionsButtonClicked() {
      this.submitButtonDisabled = true;
      const fixture = {};
      await this.leagueFixturesArray.asyncForEach(async (match) => {
        fixture.msisdn = this.user.msisdn;
        fixture.roundId = this.$route.params.roundId
        fixture.soccerFixtureId = match.FixtureID ? match.FixtureID : match.SoccerFixtureID;
        fixture.eventTypeId = 1;
        fixture.team1Id = match.Team1Id
        fixture.team2Id = match.Team2Id
        fixture.team1Score = match.Team1Score;
        fixture.team2Score = match.Team2Score;
        await GameDataService.enterSoccerRoundPredictions(fixture, this.user.msisdn);
      });
      this.successfulSubmissionPopup = true;
    },
    editPredictionsClicked() {
      this.submitButtonDisabled = false;
      this.successfulSubmissionPopup = false;
    },
    homeButtonClicked() {
      this.setRoundEntered(true);
      this.$router.push('/landing');
    },
    displayTeamName(teamName) {
      this.displayClubName = true
      return teamName
    },
    async checkIfRoundPreviouslyEntered() {
      this.leagueFixturesArray = [];
      let fixturesEnteredResponse = await GameDataService.getPlayerRoundPredictions(`${this.user.msisdn}`, `${this.$route.params.roundId}`);
      if (fixturesEnteredResponse.data.roundPredictions.length > 0) {
        this.leagueFixturesArray = fixturesEnteredResponse.data.roundPredictions;
      } else {
        let leagueFixtures = await GameDataService.getLeagueFixtures(`${this.user.msisdn}`, `${this.$route.params.roundId}`);
        this.addRoundFixturesDefault(leagueFixtures.data.fixtures);
      }
    },
    addRoundFixturesDefault(fixtures) {
      this.leagueFixturesArray = []
      fixtures.forEach((fixture) => {
        fixture.Team1Score = 0;
        fixture.Team2Score = 0;
        this.leagueFixturesArray.push(fixture);
      });
    },
    dateConversion(fixtures) {
      this.formattedDate = moment(fixtures[0].StartDate).format('dddd, D MMMM');
      this.formattedTime = moment(fixtures[0].StartDate).format('h:mma');
    },
    checkMatchNotStarted(fixture) {
      return moment(fixture.StartDate).isAfter(new Date()) === true;
    },
    autoPredictButtonClicked(fixtures) {
      fixtures.forEach((match) => {
        match.Team1Score = this.resultScoreRandomizer(0, 5);
        match.Team2Score = this.resultScoreRandomizer(0, 5);
      })
      this.scrollToSubmitButton()
    },
    resultScoreRandomizer(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    scrollToSubmitButton() {
      const submitButtonId = document.getElementById("submitButton");
      submitButtonId.scrollIntoView({behavior: 'smooth', block: 'center'});
    },
    openCloseEarnPointsPopUp() {
      this.setEarnPointsPopup(true)
    },
    cancelSubscriptionClicked() {
      this.successfulSubmissionPopup = !this.successfulSubmissionPopup;
      this.doiTwoPopUp = !this.doiTwoPopUp;
    },
    async confirmSubscriptionClicked() {
      await UtilsService.doiTwo(this.user.msisdn);
      const subscriptionResponse = await UserDataService.subscribeV2(this.user.msisdn, this.user.token);

      if (subscriptionResponse.data.result === true) {
        window.open(`${environmentConfig.predictorHost}/?fantasyToken=${this.user.token}`, '_self');
      } else if (subscriptionResponse.data.error) {
        this.subscriptionError = true
      }
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    await this.checkIfRoundPreviouslyEntered()
    this.dateConversion(this.leagueFixturesArray);
    this.setIsLoading(false);
  },
  mounted() {
    let webViewScript = document.createElement('script')
    webViewScript.setAttribute('src', 'https://appx/web-view.min.js')
    document.head.appendChild(webViewScript)
  },
}
</script>

<style scoped>
.body-container {
  background-color: #e8e8e8;
  overflow-y: hidden;
  padding-bottom: 50px;
}

.round-date-time {
  font-weight: 600;
  height: 30px;
  font-size: 15px;
  background-color: #2D343B;
  color: #FFFFFF;
}

.match-badges-incrementer-container {
  position: relative;
  border-bottom: .5px solid #a8a8a8;
}

.badge-icon {
  width: 40px;
}

.minus-decrement, .plus-incrementer {
  height: 40px;
  font-weight: 700;
  font-size: 22px;
  background-color: #bfa668;;
  color: #FFFFFF;
}

.score-value {
  font-weight: 700;
  font-size: 22px;
}

.submit-predictions-button {
  height: 45px;
  font-size: 15px;
  border: 0;
  color: #FFFFFF;
  background-color: #EF151C;
}

.submit-predictions-container {
  position: relative;
}

.prediction-submitted-container {
  position: absolute;
  left: 12.5%;
  bottom: 10px;
  width: 75%;
  border: 1px solid #bfa668;
}


.submission-header-text {
  font-weight: 700;
  font-size: 17px;
  border-bottom: .5px solid #FFFFFF;
}

.join-now-button {
  background-color: #bfa668;
  color: #FFFFFF;
  border: 0;
  height: 45px;
  font-size: 16px;
}

.background-opacity-container {
  background-color: #FFFFFF;
  opacity: 0.95;
}

.home-button {
  height: 45px;
  font-size: 16px;
  background-color: #FFFFFF;
  color: #bfa668;
  border: 1px solid #bfa668;
}

.team-name {
  font-size: 10px;
}

.earn-points-button, .auto-predict-button {
  font-size: 16px;
  color: #FFFFFF;
  height: 30px;
  font-weight: 600;
  background-color: #EF151C;
  width: 46%;
}

.how-to-win-container {
  position: absolute;
  width: 85%;
  top: 30%;
}

.more-stats-icon {
  position: absolute;
  right: -2px;
  top: 12px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1.5px solid #bfa668;;;
  background-color: #bfa668;
}

.text-more-stats-icon {
  position: absolute;
  right: 7.5px;
  top: -2.5px;
  font-size: 15px;
  font-weight: 600;
  color: #FFFFFF;
}

.sub-error-text {
  color: #EF151C;
  font-size: 13px;
  margin-bottom: 10px;
}

</style>
